import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    items: [],
  },
  reducers: {
    replaceBlogs(state, action) {
      state.items = action.payload.items;
    },
    addBlog(state, action) {
      const index = state.items.findIndex(
        (blog) => blog.id === action.payload.blog.id
      );
      if (index > -1) {
        state.items[index] = action.payload.blog;
      } else {
        state.items.push(action.payload.blog);
      }
    },
    deleteBlog(state, action) {
      if (action.payload.id) {
        state.items = state.items.filter(
          (blog) => blog.id !== action.payload.id
        );
      }
    },
    updateBlog(state, action) {
      const index = state.items.findIndex(
        (blog) => blog.id === action.payload.blog.id
      );

      if (index !== -1) {
        state.items[index] = action.payload.blog;
      }
    },
  },
});

export const blogActions = blogSlice.actions;
export default blogSlice;
