import { Box, Paper, Skeleton, Stack } from "@mui/material";

const NewsLoadingCard = () => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "335px",
        padding: "10px",
        gap: "3px",
        ":hover": {
          cursor: "pointer",
          transform: "translateY(-7px)",
          transition: "all 0.5s",
          boxShadow:
            "0px 2px 20px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <Skeleton variant="rounded" width={319} height={140} />
      {/* Content */}
      <Stack gap={"3px"}>
        {/* <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> */}
        <Skeleton variant="rounded" width={319} height={30} />
        <Skeleton variant="rounded" width={319} height={75} />
        <Stack direction="row" gap={"10px"}>
          <Skeleton variant="rounded" width={64} height={30} />
          <Skeleton variant="rounded" width={93} height={30} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default NewsLoadingCard;
