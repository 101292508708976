import "./App.css";
import ContactUs from "./pages/contactUs";
import LandingPage from "./pages/LandingPage/landingPage";
import ServiceDetailPage from "./pages/ServiceDetailPage/ServiceDetailPage";
import AboutUs from "./pages/AboutUs/AboutUs";
import {
  ABOUT_US_ROUTE,
  ALL_BLOGS_ROUTE,
  BLOG_PAGE_ROUTE,
  CONTACT_US_ROUTE,
  HOME_PAGE_ROUTE,
  SERVICE_PAGE_ROUTE,
  SUCCES_PAGE_ROUTE as SUCCESS_PAGE_ROUTE,
} from "./util/Constants";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import BlogDetailPage from "./pages/BlogDetailPage/BlogDetailPage";
import TidyCalEmbed from "./components/TidyCal/TidyCal";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { uiActions } from "./store/uiSlice";
import { subscribeToAuthChanges } from "./services/AuthService";
import { useEffect } from "react";
import LoginView from "./components/login/login-view";
import SuccessPage from "./pages/SuccessPage";

import TestAcknowledgementSection from "./pages/test";

const router = createBrowserRouter([
  {
    path: "",
    element: <RootLayout />,
    children: [
      { path: HOME_PAGE_ROUTE, element: <LandingPage /> },
      { path: ABOUT_US_ROUTE, element: <AboutUs /> },
      { path: CONTACT_US_ROUTE, element: <ContactUs /> },
      { path: BLOG_PAGE_ROUTE + "/:id", element: <BlogDetailPage /> },
      { path: SERVICE_PAGE_ROUTE + "/:id", element: <ServiceDetailPage /> },
      { path: SUCCESS_PAGE_ROUTE, element: <SuccessPage /> },
      { path: "/test", element: <TestAcknowledgementSection /> },
      { path: "login", element: <LoginView /> },
      // { path: "/blog/:id", element: <BlogDetailPage /> },
      // { path: ABOUT_US_ROUTE, element: <AboutUsPage /> },
      // { path: ALL_BLOGS_ROUTE, element: <BlogListPage /> },
    ],
  },
  // {
  //   path: "/admin",
  //   element: <AdminLayout />,
  //   children: [
  //     { path: "addBlog/:id", element: <AddBlogPage /> },
  //     { path: "list/:serviceName", element: <ListAllItemsPage /> },
  //     // { path: "/blog/:id", element: <BlogDetailPage /> },
  //     // { path: ABOUT_US_ROUTE, element: <AboutUsPage /> },
  //     // { path: "/test", element: <h1>this is test</h1> },
  //   ],
  // },
]);

function App() {
  const uiState = useSelector((state) => state.ui);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeToAuthChanges();
  }, [dispatch]);

  const handleClose = () => {
    dispatch(uiActions.resetNotification());
  };
  return (
    <div>
      {/* To show snackbar */}
      <Snackbar
        open={uiState.message !== ""}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={uiState.severity || "info"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {uiState.message}
        </Alert>
      </Snackbar>
      <RouterProvider router={router} />
    </div>
  );
  // return (
  // <div>
  {
    /* <HomePage /> */
  }
  {
    /* <ContactUs /> */
  }
  {
    /* <ServiceDetailPage /> */
  }
  {
    /* <LandingPage />  */
  }
  {
    /* <AboutUs /> */
  }
  {
    /* <ContactUs /> */
  }
  {
    /* </div> */
  }
  // );
}

export default App;
