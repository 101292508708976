import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Rating,
  Icon,
  Avatar,
  Box,
} from "@mui/material";
import { FormatQuote } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import { responsive } from "../sections/LatestNews/LatestNews";

const AcknowledgementSection = (props) => {
  const reviews = [
    {
      name: "John Doe",
      company: "ABC Corporation",
      review:
        "Exceptional finance and accounting services! This platform has truly transformed the way I manage my finances.",
      rating: 5,
      image:
        "https://img.freepik.com/premium-photo/thoughtful-11yearold-british-boy-with-finger-chin_1308-153562.jpg",
    },
    {
      name: "Jane Smith",
      company: "XYZ Inc.",
      review:
        "Bookkeeping services are top-notch! They have streamlined our accounting processes and saved us a lot of time.",
      rating: 4,
      image: "https://via.placeholder.com/50x50",
    },
    {
      name: "Bob Johnson",
      company: "DEF Ltd.",
      review:
        "Their bookkeeping expertise has helped us maintain accurate financial records effortlessly.",
      rating: 5,
      image: "https://via.placeholder.com/50x50",
    },
    {
      name: "Bob Johnson",
      company: "DEF Ltd.",
      review:
        "Exceptional finance and accounting services! This platform has truly transformed the way I manage my finances.",
      rating: 5,
      image: "https://via.placeholder.com/50x50",
    },
  ];

  const caroual = (
    <Box
      sx={{ width: "90vw", margin: "10px auto 70px auto" }}
      className={`animate__animated ${
        true ? "animate__fadeInUp" : "invisible"
      }`}
    >
      <Carousel
        // ssr={true} // means to render carousel on server-side.
        // autoPlay={true}
        // autoPlaySpeed={1000}
        // customTransition="all .5"
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={false}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        arrows={false}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {reviews.map((review, index) => (
          <Grid item  key={index}>
            <Card
              style={{
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              <CardContent style={{ padding: 20 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <Avatar
                      src={review.image}
                      alt={review.name}
                      style={{ width: 50, height: 50, borderRadius: 50 }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="body1"
                      style={{ fontSize: 16, color: "#333" }}
                    >
                      <Icon
                        fontSize="large"
                        style={{ marginRight: 10, color: "#666" }}
                      >
                        <FormatQuote />
                      </Icon>
                      {review.review}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ fontSize: 14, color: "#666", marginBottom: 10 }}
                    >
                      {review.name} - {review.company}
                    </Typography>
                    <Rating
                      value={review.rating}
                      readOnly
                      style={{ fontSize: 18, color: "#F7DC6F" }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Carousel>
    </Box>
  );

//   return <Box>{caroual}</Box>;

  return (
    <Grid
      container
      spacing={2}
      style={{ padding: 20, backgroundColor: "#f7f7f7" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h2"
          style={{
            marginBottom: 20,
            fontSize: 24,
            fontWeight: 500,
            color: "#333",
          }}
        >
          Acknowledgements
        </Typography>
      </Grid>
      {reviews.map((review, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            style={{
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <CardContent style={{ padding: 20 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <Avatar
                    src={review.image}
                    alt={review.name}
                    style={{ width: 50, height: 50, borderRadius: 50 }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 16, color: "#333" }}
                  >
                    <Icon
                      fontSize="large"
                      style={{ marginRight: 10, color: "#666" }}
                    >
                      <FormatQuote />
                    </Icon>
                    {review.review}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 14, color: "#666", marginBottom: 10 }}
                  >
                    {review.name} - {review.company}
                  </Typography>
                  <Rating
                    value={review.rating}
                    readOnly
                    style={{ fontSize: 18, color: "#F7DC6F" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AcknowledgementSection;
