import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import store from "../store";
import { authActions } from "../store/authSlice";
import { auth } from "./firebase";

export const register = async (email, password) => {
  store.dispatch(authActions.setLoading(true));
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    store.dispatch(authActions.setUser(userCredential.user));
  } catch (error) {
    store.dispatch(authActions.setError(error.message));
  } finally {
    store.dispatch(authActions.setLoading(false));
  }
};

export const login = async (email, password) => {
  store.dispatch(authActions.setLoading(true));
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    store.dispatch(authActions.setUser(userCredential.user));
  } catch (error) {
    store.dispatch(authActions.setError(error.message));
  } finally {
    store.dispatch(authActions.setLoading(false));
  }
};

export const logout = async () => {
  store.dispatch(authActions.setLoading(true));
  try {
    await signOut(auth);
    store.dispatch(authActions.setUser(null));
  } catch (error) {
    store.dispatch(authActions.setError(error.message));
  } finally {
    store.dispatch(authActions.setLoading(false));
  }
};

export const subscribeToAuthChanges = () => {
  onAuthStateChanged(auth, (user) => {
    console.log("auth observer is called");
    console.log(user);
    store.dispatch(authActions.setUser(user));
  });
};

/////

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    // if user is logged in successfully
    if (result.user) {
      store.dispatch(authActions.setUser(result.user));
    }
    return result.user;
  } catch (error) {
    store.dispatch(authActions.setError(error.message));
  } finally {
    store.dispatch(authActions.setLoading(false));
  }
  return null;
  // add user to firestore
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};
