import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    items: [],
    count: 0,
  },
  reducers: {
    updateCount(state, action) {
      state.count = action.payload.count;
    },
    replaceServices(state, action) {
      state.items = action.payload.items;
    },
    addService(state, action) {
      state.items.push(action.payload.service);
    },
  },
});

export const serviceActions = serviceSlice.actions;
export default serviceSlice;
