export const HOME_PAGE_ROUTE = "/";
export const ABOUT_US_ROUTE = "/aboutUs";
export const CONTACT_US_ROUTE = "/contactUs";

export const BLOG_PAGE_ROUTE = "/blog";
export const ROUTE_SEPARATOR = "/";

export const SERVICE_PAGE_ROUTE = "/service";
export const SUCCES_PAGE_ROUTE = "/success";

export const ALL_BLOGS_ADMIN_ROUTE = "/admin/list/blog";
export const ALL_BLOGS_ROUTE = "/list/blog";

export const PAGE_SIZE = 1;
