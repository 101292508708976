import { Box, Paper, Skeleton } from "@mui/material";

const ServiceLoadingCard = () => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "305px",
        padding: "10px",
        ":hover": {
          cursor: "pointer",
          transform: "translateY(-7px)",
          transition: "all 0.5s",
          boxShadow:
            "0px 2px 20px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <Skeleton variant="rounded" width={150} height={150} />
      {/* Content */}
      <Box>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="rounded" width={300} height={120} />
      </Box>
    </Paper>
  );
};

export default ServiceLoadingCard;
