import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBa3OzAskgFcm3UZydFeE2xYVodrYN3Ajw",
  authDomain: "shaqglobalconsultatnts.firebaseapp.com",
  projectId: "shaqglobalconsultatnts",
  storageBucket: "shaqglobalconsultatnts.appspot.com",
  messagingSenderId: "514345835124",
  appId: "1:514345835124:web:24d2b826adf1a217e74f21",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
