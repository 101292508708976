import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  Divider,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import classes from "./style.module.scss";
import "../../App.css";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { findServiceById } from "../../store/serviceActions";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import MainHeading from "../../components/Typo/MainHeading";
import { CONTACT_US_ROUTE } from "../../util/Constants";
import Paragraph from "../../components/Typo/Paragraph";

const ServiceDetailPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const serviceState = useSelector((state) => state.service);
  const [service, setService] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConnectClickHandler = async () => {
    navigate(CONTACT_US_ROUTE);
  };

  useEffect(() => {
    console.log("use effect is called for detail page");
    let service = serviceState.items.find((service) => service.id === id);
    if (!service) {
      dispatch(findServiceById(id));
    }
    setService(service);
  }, [serviceState.items.length, id]);

  if (!service) {
    return <LoadingComponent />;
  }

  const header = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(rgba(24, 24, 27, 0.85), rgba(24, 24, 27, 0.85)), url(${service.backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
      className={classes.header}
    >
      <Typography
        variant="h1"
        sx={{
          color: theme.palette.primary.main,
        }}
        className="headingPrimary animate__animated  animate__fadeInDown"
      >
        {service.name}
      </Typography>
      <Paragraph sx={{ color: "white" }}>{service.subTitle}</Paragraph>
      <Box sx={{ textAlign: "center", margin: "20px" }}>
        <Button
          size="large"
          variant="outlined"
          sx={{
            color: theme.palette.otherColor.textColor,
            textTransform: "none",
          }}
          startIcon={<ConnectWithoutContactIcon />}
          onClick={onConnectClickHandler}
        >
          Connect with Us
        </Button>
      </Box>
    </Box>
  );

  const headingGrid = (
    <Container maxWidth="lg" sx={{ py: 4, px: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Card sx={{ height: 250, boxShadow: 2 }}>
            <CardMedia
              component="img"
              image={service.imgUrl1}
              alt="Accounting Image"
              sx={{ height: "100%", objectFit: "cover" }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box sx={{ py: 2, px: { sm: 1, lg: 4 } }}>
            <MainHeading>{service.title}</MainHeading>
            <Paragraph sx={{ textAlign: "justify !important" }}>
              {service.titleDesc}
            </Paragraph>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4, borderColor: "#ddd" }} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MainHeading>Our Strategic Financial Management Services</MainHeading>
          <Paragraph sx={{ textAlign: "justify" }}>
            {service.description}
          </Paragraph>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List sx={{ pl: 0, pt: "10px", pb: 2 }}>
            {service.descriptionPoints.map((point) => (
              <ListItem sx={{ py: 1 }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CheckCircleOutline sx={{ fontSize: 24, color: "#4caf50" }} />
                </ListItemIcon>
                {/* <ListItemText primary={point.label} sx={{ fontSize: 16 }} /> */}
                <Paragraph>{point.label}</Paragraph>
              </ListItem>
            ))}

            {/* ... */}
          </List>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4, borderColor: "#ddd" }} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MainHeading sx={{ color: "black", textTransform: "capitalize" }}>
            3 Simple steps to {service.name}
          </MainHeading>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Card sx={{ height: 250, boxShadow: 2 }}>
            <CardMedia
              component="img"
              image="https://www.shutterstock.com/image-photo/question-solution-symbol-businessman-turns-260nw-1918892036.jpg"
              // image="https://vyde.io/wp-content/uploads/2023/09/annie-spratt-vggn0xldy8s-unsplash-2.webp"
              alt="Tax Savings Image"
              sx={{ height: "100%", objectFit: "cover" }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <List sx={{ pl: 0, pt: 0, pb: 2 }}>
            {service.simplePoints.map((point) => (
              <ListItem sx={{ py: 1 }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CheckCircleOutline sx={{ fontSize: 24, color: "#4caf50" }} />
                </ListItemIcon>
                <Paragraph>{point.label}</Paragraph>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <Box>
      {header}
      {headingGrid}
    </Box>
  );
};

export default ServiceDetailPage;
