import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import classes from "./style.module.scss";
import "../../App.css";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import MyAppBar from "../../components/Navbar/NavBar";
import { useTheme } from "@emotion/react";
import AboutImg from "../../assets/images/about-1.jpg";
import Footer from "../../components/Footer/footer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Carousel from "react-multi-carousel";
import { responsive } from "../../sections/LatestNews/LatestNews";
import MainHeading from "../../components/Typo/MainHeading";
import Paragraph from "../../components/Typo/Paragraph";
import ShortHeading from "../../components/Typo/shortHeadings";

const professionalList = [
  {
    name: "Muhammad Waqas Rasheed",
    designation: "Full Stack Developer",
    imageUrl:
      "https://media.istockphoto.com/id/1399565382/photo/young-happy-mixed-race-businessman-standing-with-his-arms-crossed-working-alone-in-an-office.jpg?s=612x612&w=0&k=20&c=buXwOYjA_tjt2O3-kcSKqkTp2lxKWJJ_Ttx2PhYe3VM=",
  },
  {
    name: "Tony stark",
    designation: "Accountant",
    imageUrl:
      "https://media.istockphoto.com/id/1830126474/photo/portrait-of-a-business-man-sitting-in-an-office.webp?b=1&s=170667a&w=0&k=20&c=76Nn5zkKOesEFIUl4_6FmwYAjtyqXXvUGKko48yBMmk=",
  },
  {
    name: "Haward Stark",
    designation: "Data Analyst",
    imageUrl:
      "https://media.istockphoto.com/id/1448167415/photo/smiling-indian-businessman-in-suit-and-glasses-with-laptop-near-office-building.webp?b=1&s=170667a&w=0&k=20&c=kQ-sWPkU5XP8sG1-MIrGnTe9SkLQBjGvVsa2X8uVHfc=",
  },
  {
    name: "Micheal Jhonson",
    designation: "Tax Analayst",
    imageUrl:
      "https://ath2.unileverservices.com/wp-content/uploads/sites/3/2017/09/professional-mens-hairstyles-combed-min-1024x683.jpg",
  },
];

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{ height: 10, borderRadius: 5 }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 10 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const AboutUs = (props) => {
  const theme = useTheme();
  const header = (
    <Box class={classes.aboutContainer}>
      {/* Heading */}
      <Box className={classes.test}>
        <Typography
          sx={{
            margin: "0 !important",
            lineHeight: 0.7,
            color: theme.palette.primary.main,
          }}
          className="headingPrimary greenText animate__animated  animate__fadeInDown"
        >
          Who
        </Typography>
        <Typography
          sx={{
            margin: "0 !important",
            lineHeight: 0.7,
            color: theme.palette.primary.main,
          }}
          className="headingPrimary whiteText animate__animated  animate__fadeInDown"
        >
          we are?
        </Typography>
        <ShortHeading sx={{textAlign:"left",color:"#fff"}}>
        Empowering your financial future
        <br/> with trusted and expert services
        </ShortHeading>
      </Box>
    </Box>
  );

  const whoWeAreSection = (
    <Box
      sx={{
        flexDirection: { xs: "column", md: "row", padding: "100px 10px" },
      }}
      className="flex justify-content-center align-items-center gap-5"
    >
      <Box sx={{ flexBasis: "40%" }}>
        <img style={{ width: "100%" }} src={AboutImg} />
      </Box>

      <Box
        sx={{
          maxWidth: "510px",
          "> *": { textAlign: "left" },
          // marginLeft: { md: "50%" , sm:"30%"},
          // margin: { md: "0 0 0 50%", xs: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignSelf: { xs: "center", md: "stretch" },
        }}
      >
        {/* <Typography
          className="headingPrimary"
          sx={{
            color: theme.palette.primary.main,
            textTransform: "uppercase",
          }}
        >
          What is Shaq
        </Typography> */}
        <MainHeading>What is SHAQ</MainHeading>
        {/* <Typography sx={{ maxWidth: "100%" }} className="subHeading">
          Our theme is the most advanced and user-friendly theme you will find
          on the market, we have documentation and video to help set your site
          really easily, pre-installed demos you can import in one click and
          everything from the theme options to page content can be edited from
          the front-end. This is the theme you are looking for.
        </Typography> */}
        <Paragraph sx={{ textAlign: "justify !important" }}>
          Welcome to SHAQ, your trusted partner for business success. We
          specialize in essential finance services including accounting,
          bookkeeping, taxation, and licensing. Our dedicated team ensures
          compliance and efficiency, simplifying your business operations. With
          SHAQ, receive personalized solutions to optimize your financial
          strategy and propel your business forward.
        </Paragraph>
        <br />
        <Box>
          <Typography variant="h6">Efficient Financial Reporting</Typography>
          {/* <Paragraph sx={{ fontFamily: "serif !important" }}>Development</Paragraph> */}
          <LinearProgressWithLabel value={80} />
        </Box>
        <Box>
          <Typography variant="h6">Streamlined Bookkeeping</Typography>
          <LinearProgressWithLabel value={85} />
        </Box>
        <Box>
          <Typography variant="h6">Expense Management</Typography>
          <LinearProgressWithLabel value={90} />
        </Box>
      </Box>
    </Box>
  );

  const teamCarousal = (
    <Box sx={{ width: "90vw", margin: "10px auto 50px auto" }}>
      <Carousel
        // ssr={true} // means to render carousel on server-side.
        // autoPlay={true}
        // autoPlaySpeed={1000}
        // customTransition="all .5"
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={false}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        arrows={false}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {professionalList.map((item, index) => (
          <Box
            key={index}
            sx={{
              textAlign: "center",
              "> *": {
                textAlign: "center",
              },
              marginBottom: "30px",
            }}
          >
            <img className={classes.roundImg} src={item.imageUrl} />
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "20px" }}
            >
              {item.name}
            </Typography>
            <Typography>{item.designation}</Typography>
          </Box>
          //   <Card sx={{ maxWidth: 345 }}>
          //     <CardMedia
          //       sx={{ height: 140 }}
          //       // image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
          //       image={item.imageUrl}
          //       title="green iguana"
          //     />
          //     <CardContent>
          //       <Typography gutterBottom variant="h5" component="div">
          //         {item.name}
          //       </Typography>
          //       <Typography variant="body2" color="text.secondary">
          //         {item.shortDescription}
          //       </Typography>
          //     </CardContent>
          //     <CardActions>
          //       <Button size="small">Share</Button>
          //       <Button size="small">Learn More</Button>
          //     </CardActions>
          //   </Card>
        ))}
      </Carousel>
    </Box>
  );
  return (
    <Stack>
      {/* <MyAppBar /> */}
      {header}
      {whoWeAreSection}
      
      {/*  */}
      {/* {teamCarousal} */}
      {/* <Footer /> */}
    </Stack>
  );
};

export default AboutUs;
