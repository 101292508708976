import { Box, Stack } from "@mui/material";
import Footer from "../components/Footer/footer";
import { Outlet } from "react-router-dom";
import MyAppBar from "../components/Navbar/NavBar";

const RootLayout = () => {
  return (
    <Stack>
      <MyAppBar />
      <Outlet />
      <Footer />
    </Stack>
  );
};

export default RootLayout;
