import "animate.css";
import "../../App.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTheme } from "@emotion/react";
import MainHeading from "../../components/Typo/MainHeading";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/uiSlice";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const navigateToGoogleMaps = () => {
  const officeLocation = "https://maps.app.goo.gl/FsD8pcph5hi4DQha8";
  window.open(officeLocation, "_blank");
};

const LocationSection = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);
  const dispatch = useDispatch();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        dispatch(
          uiActions.setNotification({
            message: "Copied To Clipboard",
            severity: "success",
          })
        );
      },
      (err) => {
        dispatch(
          uiActions.setNotification({
            message: "Error while copying to Clipboard",
            severity: "error",
          })
        );
        console.error("Could not copy text: ", err);
      }
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 1 } // Trigger animation when at least 25% of the element is visible
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={serviceRef}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {/* Location and Contact Info */}
      <Box
        className={`${
          isVisible ? "animate__animated animate__zoomIn" : "invisible"
        }`}
        sx={{ flex: 1, minWidth: "300px" }}
      >
        <Box
          sx={{
            background: "",
            "> * ": {
              color: "black !important",
              width: "max-content",
              ":not(:first-child)": {
                ":hover": {
                  cursor: "pointer",
                  transform: "translateY(-7px)",
                  transition: "all 0.5s",
                  // boxShadow:
                  //   "0px 2px 20px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                },
              },
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <MainHeading>Our Location On Map</MainHeading>
          <Button
            onClick={() =>
              copyToClipboard("12000 Ford Rd, Farmers Branch, TX 75234, USA")
            }
            sx={{ textTransform: "none" }}
            variant="text"
            startIcon={<LocationOnIcon />}
          >
            12000 Ford Rd, Farmers Branch, TX 75234, USA
          </Button>
          <Button
            onClick={() => copyToClipboard("+1-918-537-4472")}
            variant="text"
            startIcon={<PhoneIcon />}
          >
            +1-918-537-4472
          </Button>
          <Button
            sx={{ alignSelf: "center", color: "white !important" }}
            startIcon={<AccessTimeIcon />}
            variant="contained"
            onClick={navigateToGoogleMaps}
          >
            Sunday - Friday <br /> 9:00 am - 9:00 pm
          </Button>
        </Box>
      </Box>
      {/* Location image */}
      <Box
        className={`${
          isVisible ? "animate__animated animate__zoomIn" : "invisible"
        }`}
        sx={{
          padding: "1rem",
          flex: 1,
          // background: "orange",
          marginRight: { xs: "0rem", sm: "0px", md: "1rem", xl: "10rem" },
          textAlign: "center",
        }}
      >
        {/* <img style={{ width: "100%" }} src={locationImge} alt="pic missing" /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.4642297806004!2d-96.88983759999999!3d32.912331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c27bd67ff3a15%3A0x42101f45b3ee7b32!2s12000%20Ford%20Rd%2C%20Farmers%20Branch%2C%20TX%2075234%2C%20USA!5e0!3m2!1sen!2s!4v1721591203861!5m2!1sen!2s"
          style={{
            border: 0,
            width: "100%",
            minWidth: "300px",
            // width: { lg: "655px", md: "400px" },
            height: "357px",
          }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>

      {/* <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
        </GoogleMapReact>
      </div> */}
    </Box>
  );
};

export default LocationSection;
