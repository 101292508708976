import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";

const MainHeading = ({ children, sx, ...rest }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h2" // Changed from "body1" to "h2" for a more prominent heading
      className="animate__animated  animate__fadeInDown"
      sx={{
        // Removed "justify" as it's not suitable for headings
        marginBottom: "1.5rem", // Increased margin bottom for better spacing
        fontWeight: 700, // Removed "!important" as it's not necessary
        // color: theme.palette.primary.main,
        fontSize: {
          xs: 20, // Increased font size for better readability
          sm: 24,
          md: 30,
          lg: 36,
          xl: 40,
        },
        lineHeight: 1.2, // Added line height for better typography
        letterSpacing: 0.5, // Added letter spacing for better readability
        ...sx, // Merge custom sx props
      }}
      {...rest} // Pass any other props to Typography
    >
      {children}
    </Typography>
  );
};

export default MainHeading;
