import { Box, Button, Stack, Typography } from "@mui/material";
import MyAppBar from "../../components/Navbar/NavBar";
import classes from "./style.module.scss";
import { ReactTyped } from "react-typed";
import person from "../../assets/images/person.jpg";
import { WidthFull } from "@mui/icons-material";
import "../../App.css";
import Header from "../../components/Header/Header";
import ServiceSection from "../../sections/ServiceSection/servicesSection";

import ExpertiseSection from "../../sections/ExpertiseSection/ExpertiseSection";
import AnimatedWorkSection from "../../sections/AnimatedWorkSection/AnimatedWorkSection";
import StatsSection from "../../sections/StatsSection/StatsSection";
import Footer from "../../components/Footer/footer";
import LatestNews from "../../sections/LatestNews/LatestNews";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchBlogs } from "../../store/blogActions";
import ServiceLoadingCard from "../../components/card/ServiceCard";
import AcknowledgmentSection from "../../sections/AcknowledgmentSection/AcknowledgmentSection";

const LandingPage = () => {
  // const dispatch = useDispatch();
  // const blogList = useSelector((state) => state.blog.items);
  // useEffect(() => {
  //   if (blogList.length === 0) {
  //     dispatch(fetchBlogs());
  //   }
  // }, []);
  const mainHeading = (
    <Typography
      className="animate__animated  animate__fadeInDown"
      sx={{
        fontSize: { xs: "3rem", md: "4rem" },
        fontFamily: "Raleway !important",
        fontWeight: 700,
        backgroundImage: "linear-gradient(to right,  #28b485,#ff1f08)",
        color: "transparent",
        backgroundClip: "text",
        lineHeight: "1.5",
      }}
    >
      {" "}
      Revolutionizing
      <br />
      Industry With <br />
      <ReactTyped
        strings={["Innovation", "Consultation", "Development"]}
        typeSpeed={40}
        backSpeed={50}
        loop
      />
    </Typography>
  );

  const subHeading = (
    <Typography
      variant="subtitle1"
      sx={{ color: "#fff", marginBottom: "10px" }}
      gutterBottom
      // className="subHeading"
    >
      We're An IT Agency Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    </Typography>
  );

  const actionButtons = (
    <Box sx={{ margin: "30px 0 0 0" }}>
      <Button
        variant="outlined"
        size="large"
        sx={{ marginRight: "40px", marginBottom: "0px", textTransform: "none" }}
      >
        Connect with us
      </Button>
      <Button variant="contained" size="large">
        Services offered
      </Button>
    </Box>
  );
  return (
    <Stack>
      {/* <MyAppBar /> */}

      <Header />

      <ServiceSection />

      {/* <AnimatedWorkSection /> */}
      <StatsSection />
      <LatestNews />
      <Box sx={{ margin: "50px 0" }}>
        <AcknowledgmentSection />
      </Box>

      {/* <ExpertiseSection /> */}
      {/* <Footer /> */}
    </Stack>
  );
};

export default LandingPage;
