import React, { useEffect } from "react";
import "../../App.css";

const TidyCalEmbed = () => {
  useEffect(() => {
    
    // Ensure the script is only added once
    if (!document.getElementById("tidycal-script")) {
      console.log("if case running ");
      const script = document.createElement("script");
      console.log(script);
      script.id = "tidycal-script";
      script.src = "https://asset-tidycal.b-cdn.net/js/embed.js";
      script.async = true;
      document.body.appendChild(script);
      // const el = document.getElementById("powered_by_prefooter");
      // console.log("displaying lement");
      // console.log(el);
    } else {
      console.log("else case running");
    }

    // Cleanup the script if the component is unmounted
    return () => {
      const script = document.getElementById("tidycal-script");
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div
      className="tidycal-embed"
      data-path="waqasrasheed5005/30-minute-meeting"
    ></div>
  );
};

export default TidyCalEmbed;
{
  /* <div class="tidycal-embed" data-path="waqasrasheed5005/30-minute-meeting"></div>
<script src="https://asset-tidycal.b-cdn.net/js/embed.js" async></script> */
}
