import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    message: '',
    severity: '',
  },
  reducers: {
    setNotification(state, action) {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    resetNotification(state, action) {
      state.message = '';
      state.severity = '';
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;
