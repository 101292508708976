import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

const BarHeading = (props) => {
  const theme = useTheme();

  const separator = (
    <Box
      className="separator"
      sx={{
        flex: 1,
        display: { xs: "none", sm: "block" },
        background: theme.palette.primary.textColor,
      }}
    />
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "10px",
        ...props.sx
      }}
    >
      {separator}
      <Typography
        // variant={"h4"}
        sx={{
          fontWeight: "600",
          color: theme.palette.primary.textColor,
          margin: { sm: "auto", xs: "auto", md: "0px" },
          fontSize:{xs:"34px",sm:"48px"}
        }}
        // color="text.secondary"
      >
        {props.children}
      </Typography>
      {separator}
    </Box>
  );
};

export default BarHeading;
