import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, styled, Paper, SvgIcon } from "@mui/material";

import classes from "./style.module.css";
import "animate.css";
import { useTheme } from "@emotion/react";
import { Height } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../store/serviceActions";
import { SERVICE_PAGE_ROUTE } from "../../util/Constants";
import { useNavigate, useParams } from "react-router";
import ServiceLoadingCard from "../../components/card/ServiceCard";
import BarHeading from "../../components/Typo/BarHeading";
// imageUrl:"https://img.icons8.com/external-smashingstocks-detailed-outline-smashing-stocks/66/external-bookkeeping-banking-and-finance-smashingstocks-detailed-outline-smashing-stocks.png"
const cardsData = [
  {
    title: "Book Keeping",
    description:
      "Code Encoders specialized in public and private Blockchains; we provide top-notch Blockchain and Web3 services which will transform your business to decentralize the world.",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/shaqglobalconsultatnts.appspot.com/o/data.png?alt=media&token=a2763df0-39e5-4b55-9a64-b28ee34ec5a0",
  },
  {
    title: "Tax Solutions",
    description:
      "Boost your online presence with our web development services. Devsinc delivers high-quality design and powerful functionality tailored for your business.",
    imageUrl:
      "https://img.icons8.com/external-nawicon-mixed-nawicon/64/external-Tax-money-management-nawicon-mixed-nawicon.png",
    // imageUrl:
    //   "https://img.icons8.com/external-sbts2018-lineal-color-sbts2018/58/external-tax-payment-1-sbts2018-lineal-color-sbts2018.png",
  },
  {
    title: "Finacial Reporting",
    description:
      "Get custom software perfectly aligned with your business goals. Our solutions are designed to boost your productivity and efficiency.",
    imageUrl:
      "https://img.icons8.com/external-smashingstocks-mixed-smashing-stocks/68/external-financial-report-web-analytics-smashingstocks-mixed-smashing-stocks.png",
  },
  {
    title: "Licensing and Registration Solutions",
    description:
      "Developing business-oriented Mobile applications with cutting-edge technologies. We do cross-platform and native app development.",
    imageUrl: "https://img.icons8.com/pastel-glyph/64/certificate.png",
  },
];

const ServiceSection = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const serviceState = useSelector((state) => state.service);

  useEffect(() => {
    if (serviceState.items.length === 0) {
      dispatch(fetchServices());
    }
  }, [serviceState.items]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.25 } // Trigger animation when at least 25% of the element is visible
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  const FlexContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",

    gap: "1rem",
    alignItems: "stretch",
    alignContent: "center",
    // [theme.breakpoints.up("xl")]: {
    //   padding: "0 12rem",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   padding: "0",
    // },
    margin: "auto",
  }));

  return (
    <div id="services" ref={serviceRef}>
      {/* <Typography
        
        className={`${classes.foreground} headingPrimary animate__animated ${
          isVisible ? "animate__fadeInDown" : classes.invisible
        }`}
        sx={{
          // backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          color: theme.palette.primary.textColor,

          fontSize: {
            xs: "2rem !important",
            // md: "2.5rem !important",
            md: "3rem !important",
            lg: "4rem !important",
          },
          // color: "#4B0082",
        }}
      >
        Services being offered
      </Typography> */}

      <BarHeading sx={{ margin: "70px auto" }}>
        Services being offered
      </BarHeading>
      <FlexContainer>
        {serviceState.items.length === 0 &&
          [1, 2, 3, 4].map((e) => <ServiceLoadingCard />)}
        {serviceState.items.length > 0 &&
          serviceState.items.map((service, index) => {
            let animateClass;
            switch (index) {
              case 0:
                animateClass = classes.animateRight;
                break;
              case 3:
                animateClass = classes.animateLeft;
                break;
              default:
                if (Math.floor(index / 2) === 0)
                  animateClass = "animate__animated animate__fadeInDown";
                else animateClass = "animate__animated animate__fadeInUp";
                break;
            }
            return (
              <Paper
                key={index}
                // ref={serviceRef}
                className={`${animateClass} ${
                  isVisible ? classes.slideIn : ""
                }`}
                onClick={() => navigate(SERVICE_PAGE_ROUTE + "/" + service.id)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "baseline",
                  width: "302px",
                  padding: "10px",
                  ":hover": {
                    cursor: "pointer",
                    transform: "translateY(-7px)",
                    transition: "all 0.5s",
                    boxShadow:
                      "0px 2px 20px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <img
                  style={{ width: "100px", marginBottom: "1rem" }}
                  src={service.iconImageUrl}
                />
                {/* Content */}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {service.name.length > 26
                      ? service.name.substr(0, 26) + "..."
                      : service.name}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Roboto",
                      // textAlign: "justify",
                    }}
                  >
                    {service.titleDesc}
                  </Typography>
                </Box>
              </Paper>
            );
          })}
      </FlexContainer>
    </div>
  );
};

export default ServiceSection;
