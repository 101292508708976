import { purple } from "@mui/material/colors";
import { createTheme } from "@mui/material";

import { lightBlue, teal } from "@mui/material/colors";
const orangeRedColor = "#ff5500";
const brightBlueColor = "#0099FF";
const purpleColor = "#5000ca";
const blackColor = "#000";
const lightBlueColor = "#cce0f3";
// const goldenColor = "#FFD700";
const goldenColor = "#c09135";
const golencolor2 = "#fcb900";

export const AppTheme = createTheme({
  typography: {
    fontFamily: "serif, Arial",
  },
  palette: {
    primary: {
      // main: purple[500],
      // main: "#28b485",
      // main:teal[500]
      // main:teal[900]
      main: goldenColor,
      // textColor: "#003366",
      textColor: "#000",
    },
    secondary: {
      // main: "#f44336",
      // main: "#ff1f08",
      // main:teal[900]
      main: goldenColor,
    },
    // #28b485,#ff1f08
    // primary: {
    //   main: goldenColor,
    //   light: "#fff",
    //   dark: "#2600b3",
    //   greyColor: "#F4F6F8",
    // },

    // secondary: {
    //   main: lightBlueColor,
    //   // main: "#1C2536",
    //   // main:orangeRedColor,
    //   textColor: "#969DA7",
    //   textSecondaryColor: "#fff",
    // },
    otherColor: {
      main: "#999",
      textColor: "#fff",
    },
  },
});

export function bgGradient(props) {
  const direction = props?.direction || "to bottom";
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${
        endColor || color
      }), url(${imgUrl})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

export default AppTheme;
