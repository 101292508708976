import { useTheme } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import "animate.css";
import "../../App.css";
import Carousel from "react-multi-carousel";
import { responsive } from "../LatestNews/LatestNews";
import BarHeading from "../../components/Typo/BarHeading";

const userReviews = [
  {
    imageUrl:
      "https://img.freepik.com/premium-photo/thoughtful-11yearold-british-boy-with-finger-chin_1308-153562.jpg",
    username: "William Edwards",
    rating: 4,
    review:
      "Exceptional finance and accounting services! Truly transformed how I manage finances, highly recommended.",
  },
  {
    imageUrl:
      "https://qph.cf2.quoracdn.net/main-qimg-e0a13b533028487529902181964fb197-pjlq",
    username: "Peterson",
    rating: 5,
    review:
      "Top-notch bookkeeping services! Streamlined our accounting, saved us time. Couldn't be more satisfied.",
  },
  {
    imageUrl:
      "https://pbs.twimg.com/profile_images/1535609797810212866/hRkoo3xM_400x400.jpg",
    username: "Jenny",
    rating: 5,
    review:
      "Bookkeeping expertise is amazing! They maintain accurate records effortlessly. Truly a lifesaver for us.",
  },
  {
    imageUrl:
      "https://midhudsonnews.com/wp-content/uploads/2023/06/John-Persons-resized.jpg",
    username: "Jhon Wick",
    rating: 4,
    review:
      "Exceptional finance and accounting services! Truly transformed how I manage finances, highly recommended.",
  },
];


const AcknowledgmentSection = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.5 } // Trigger animation when at least 25% of the element is visible
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  const theme = useTheme();
  const FlexContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    justifyContent: "space-around",
    alignItems: "center",
  }));

  const caroual = (
    <Box
      sx={{ width: "90vw", margin: "10px auto 70px auto" }}
      className={`animate__animated ${
        isVisible ? "animate__fadeInUp" : "invisible"
      }`}
    >
      <Carousel
        // ssr={true} // means to render carousel on server-side.
        // autoPlay={true}
        // autoPlaySpeed={1000}
        // customTransition="all .5"
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={false}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        arrows={false}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {userReviews.map((review, index) => (
          <Card
            key={review.username}
            elevation={1}
            className={`${isVisible ? "animate__fadeInUp" : "invisible"}`}
            
            sx={{
              maxWidth: 340,
              flexGrow: 1,
              padding: "1rem;",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              transition: "all .5s ease-out",
              marginBottom:"25px",
              "&:hover": { transform: "translateY(-1rem)" },
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={review.imageUrl}
              sx={{ width: 100, height: 100 }}
            />
            <Rating
              sx={{ margin: "1rem 0" }}
              name="read-only"
              value={review.rating}
              readOnly
            />
            <CardContent sx={{ textAlign: "Center" }}>
              <Typography
                sx={{ color: theme.palette.primary.main }}
                gutterBottom
                variant="h5"
                component="div"
                fontWeight="700"
              >
                {review.username}
              </Typography>
              {/* <Typography
                sx={{
                  // fontWeight: "600",
                  fontStyle: "italic",
                  fontFamily: "Roboto",
                  textAlign:"center",
                  fontSize: "1.1rem",
                }}
                // variant="body2"
                color="text.secondary"
              >
                {review.review}
              </Typography> */}
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Roboto",
                  // textAlign: "justify",
                }}
              >
                {review.review}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Carousel>
    </Box>
  );

  return (
    // Acknowledgments Heading
    <>
      <Box ref={serviceRef} sx={{ display: "flex", flexDirection: "column" }}>
        <BarHeading>Acknowledgments</BarHeading>

        <Typography
          component={"span"}
          variant="p"
          className={`animate__animated ${
            !isVisible ? "animate__fadeInLeft" : ""
          }`}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontStyle: "italic",
            color: theme.palette.primary.main,
          }}
        >
          Some of our top customers rating and experience
        </Typography>
      </Box>
      <br />
      <br />
      {caroual}
      {/* actual card elements for customer ratings */}
      {/* {userReviewsList} */}
    </>
  );
};

export default AcknowledgmentSection;
