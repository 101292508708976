import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";

const ShortHeading = ({ children, sx, ...rest }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body1"
      sx={{
        textAlign: "justify",
        marginBottom: "1rem",
        // flexBasis: { md: '100%', lg: '40%' },
        // fontFamily: "Roboto",
        textAlign: "center", // Center align the text
        // marginTop: '1rem',   // Add some top margin
        // color: "#637381",
        // font-weight: 700 !important;
        fontFamily: "Roboto",
        fontSize: {
          xs: "14px", // Extra Small screens
          sm: "16px", // Small screens
          md: "18px", // Medium screens (default)
          lg: "20px", // Large screens
          xl: "20px", // Extra Large screens
        },
        ...sx, // Merge custom sx props
      }}
      {...rest} // Pass any other props to Typography
    >
      {children}
    </Typography>
  );
};

export default ShortHeading;

// line-height: 1.5;
//   font-size: 1rem;
//   font-family: "__Public_Sans_f258fb", "__Public_Sans_Fallback_f258fb",
//     Helvetica, Arial, sans-serif;
//   color: #637381;
//   font-weight: 400;
//   max-width: 360px;
