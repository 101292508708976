import { uiActions } from "./uiSlice";
import { serviceActions } from "./serviceSlice";
import FirebaseService from "../services/FirebaseService";

export const fetchServices = () => {
  return async (dispatch) => {
    try {
      const serviceList = await FirebaseService.fetchServices();

      dispatch(
        serviceActions.replaceServices({
          items: serviceList,
        })
      );
    } catch (error) {}
  };
};

export const findServiceById = (id) => {
  return async (dispatch) => {
    try {
      const service = await FirebaseService.findById(
        FirebaseService.servicesCol,
        id
      );

      if (!service) {
        return null;
      }
      dispatch(
        serviceActions.addService({
          service,
        })
      );
      return service;
    } catch (error) {
      console.log("fetch blog is error");
    }
  };
};
