import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../App.css";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/uiSlice";
import { fetchBlogs } from "../../store/blogActions";
import { useEffect, useRef, useState } from "react";
import ServiceLoadingCard from "../../components/card/ServiceCard";
import NewsLoadingCard from "../../components/card/NewsLoadingCard";
import BarHeading from "../../components/Typo/BarHeading";

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 550 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const items = [
  {
    name: "Accounting",
    description: "Probably the most random thing you have ever seen!",
    imageUrl:
      "https://images.pexels.com/photos/159888/pexels-photo-159888.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    dateCreated: "FEB 15, 2023",
    heading: "The Most Popular New top Business Apps",
    shortDescription:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
  },
  {
    name: "Stocks",
    description: "Probably the most random thing you have ever seen!",
    imageUrl:
      "https://images.pexels.com/photos/6802052/pexels-photo-6802052.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    dateCreated: "FEB 15, 2023",
    heading: "The Most Popular New top Business Apps",
    shortDescription:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
  },
  {
    name: "Tax",
    description: "Probably the most random thing you have ever seen!",
    imageUrl:
      "https://images.pexels.com/photos/6863191/pexels-photo-6863191.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    dateCreated: "FEB 15, 2023",
    heading: "The Most Popular New top Business Apps",
    shortDescription:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
  },
  {
    name: "Book Keeping",
    description: "Probably the most random thing you have ever seen!",
    imageUrl:
      "https://images.pexels.com/photos/4475524/pexels-photo-4475524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    dateCreated: "FEB 15, 2023",
    heading: "The Most Popular New top Business Apps",
    shortDescription:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
  },
];

const LatestNews = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.25 } // Trigger animation when at least 25% of the element is visible
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  const blogList = useSelector((state) => state.blog.items);
  useEffect(() => {
    if (blogList.length === 0) {
      dispatch(fetchBlogs());
    }
  }, []);

  const navigateToblogDetail = (id) => {
    navigate("/blog/" + id);
  };

  const copyPathToClipboard = (id) => {
    console.log("copy clipboard is called");
    // const parsedUrl = new URL(url);
    // console.log("parsed url : " + parsedUrl);
    navigator.clipboard
      .writeText("https://shaqglobalconsultatnts.web.app/blog/" + id)
      .then(
        () => {
          dispatch(
            uiActions.setNotification({
              message: "Link Copied To Clipboard",
              severity: "success",
            })
          );
        },
        (err) => {
          dispatch(
            uiActions.setNotification({
              message: "Error while copying Link to Clipboard",
              severity: "error",
            })
          );
          console.error("Could not copy text: ", err);
        }
      );
  };

  let carousal;
  if (blogList.length === 0) {
    carousal = (
      <Stack
        className={`animate__animated ${isVisible ? "animate__fadeInUp" : ""}`}
        sx={{ margin: "70px auto" }}
        gap={"10px"}
        justifyContent={"center"}
        direction={"row"}
      >
        {[1, 2, 3, 4].map((e) => (
          <NewsLoadingCard />
        ))}
      </Stack>
    );
  } else {
    carousal = (
      <Box
        sx={{ width: "80vw", margin: "70px auto" }}
        className={`animate__animated ${isVisible ? "animate__fadeInUp" : ""}`}
      >
        <Carousel
          // ssr={true} // means to render carousel on server-side.
          // autoPlay={true}
          // autoPlaySpeed={1000}
          // customTransition="all .5"
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          infinite={false}
          autoPlay={props.deviceType !== "mobile" ? true : false}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          arrows={false}
          deviceType={props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {blogList.length > 0 &&
            blogList.map((item, index) => (
              <Card
                key={index}
                sx={{
                  maxWidth: 345,
                  // margin: "10px 10px 25px 10px",
                  marginBottom:"25px",
                  ":hover": {
                    cursor: "pointer",
                    transform: "translateY(-7px)",
                    transition: "all 0.5s",
                    
                    boxShadow:
                      "0px 2px 20px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <CardMedia
                  sx={{ height: 140 }}
                  // image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                  image={item.imageUrl}
                  title="green iguana"
                />
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      // textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {item.heading.length > 25
                      ? item.heading.substr(0, 25) + "..."
                      : item.heading}
                  </Typography>
                  {/* <Typography gutterBottom variant="h5" component="div">
                    {item.heading.length > 25
                      ? item.heading.substr(0, 25) + "..."
                      : item.heading}
                  </Typography> */}
                  {/* <Typography  sx={{fontFamily:"Roboto",textAlign:"justify"}} color="text.secondary">
                    {item.shortDescription.length > 135
                      ? item.shortDescription.substr(0, 135) + "....."
                      : item.shortDescription}
                  </Typography> */}
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Roboto",
                      textAlign: "justify",
                    }}
                  >
                    {item.shortDescription.length > 135
                      ? item.shortDescription.substr(0, 135) + "....."
                      : item.shortDescription}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => copyPathToClipboard(item.id)}
                  >
                    Share
                  </Button>
                  <Button
                    onClick={() => navigateToblogDetail(item.id)}
                    size="small"
                    sx={{
                      "&:hover": {
                        transform: "translateY(-2px)",
                        transition: "all .2s",
                      },
                    }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            ))}
        </Carousel>
      </Box>
    );
  }

  return (
    <Stack ref={serviceRef}>
      <BarHeading>Resources</BarHeading>
      {carousal}
    </Stack>
  );
};

export default LatestNews;
