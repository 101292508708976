import { configureStore } from "@reduxjs/toolkit";
import blogSlice from "./blogSlice";
import uiSlice from "./uiSlice";
import serviceSlice from "./serviceSlice";
import authSlice from "./authSlice";

const store = configureStore({
  reducer: {
    blog: blogSlice.reducer,
    ui: uiSlice.reducer,
    service: serviceSlice.reducer,
    auth: authSlice.reducer,
  },
});

export default store;
