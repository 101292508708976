import { initializeApp } from "firebase/app";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
// import { ref, getStorage, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

class FirebaseService {
  static firebaseConfig = {
    apiKey: "AIzaSyBa3OzAskgFcm3UZydFeE2xYVodrYN3Ajw",
    authDomain: "shaqglobalconsultatnts.firebaseapp.com",
    projectId: "shaqglobalconsultatnts",
    storageBucket: "shaqglobalconsultatnts.appspot.com",
    messagingSenderId: "514345835124",
    appId: "1:514345835124:web:24d2b826adf1a217e74f21",
  };

  static blogsCol = "blogs";
  static servicesCol = "services";
  static contactInfoCol = "contact";

  static getDbConnection() {
    if (!FirebaseService.app) {
      FirebaseService.app = initializeApp(FirebaseService.firebaseConfig);
    }
    return getFirestore(FirebaseService.app);
  }

  static fetchBlogs = async () => {
    const blogList = [];
    const db = FirebaseService.getDbConnection();
    const q = query(
      collection(db, FirebaseService.blogsCol),
      orderBy("lastUpdated", "desc"),
      limit(10)
    );
    const data = await getDocs(q);
    data.forEach((doc) => {
      blogList.push({ id: doc.id, ...doc.data() });
    });

    return blogList;
  };

  static fetchBlogById = async (id) => {
    const docRef = doc(
      FirebaseService.getDbConnection(),
      FirebaseService.blogsCol,
      id
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      return docSnap.data();
    }
    return null;
  };

  // ---------------------------------Services----------------------------
  static fetchServices = async () => {
    const serviceList = [];
    const db = FirebaseService.getDbConnection();
    const q = query(
      collection(db, FirebaseService.servicesCol),
      orderBy("name", "asc"),
      limit(10)
    );
    const data = await getDocs(q);
    data.forEach((doc) => {
      serviceList.push({ id: doc.id, ...doc.data() });
    });

    return serviceList;
  };

  static findById = async (colName, id) => {
    const docRef = doc(FirebaseService.getDbConnection(), colName, id);
    const documentSnapshot = await getDoc(docRef);
    if (documentSnapshot.exists) {
      const documentData = documentSnapshot.data();

      return documentData;
    } else {
      return null;
    }
  };

  // ---------------------------------Contact----------------------------
  static writeToCollection = async (colName, uid, contactInfo) => {
    console.log(contactInfo);
    const userRef = doc(FirebaseService.getDbConnection(), colName, uid);
    const docSnap = await getDoc(userRef);
    
    if (docSnap.exists()) {
      await updateDoc(userRef, {
        data: arrayUnion(contactInfo), // Assuming 'data' is the field name where you store the array
      });
      console.log("Document updated with new data for user ID: ", uid);
    } else {
      console.log("else case running");
      await setDoc(userRef, {
        data: [contactInfo],
      });
      console.log("New document created with initial data for user ID: ", uid);
    }
    // await addDoc(
    //   collection(FirebaseService.getDbConnection(), colName),
    //   contactInfo
    // );
  };
}

export default FirebaseService;
