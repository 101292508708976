// import * as React from "react";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Box,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Divider,
  Collapse,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import AppLogo from "../../../src/assets/logo.png";
import classes from "./style.module.scss";
import contactGoldenIcon from "../../../src/assets/images/contact-icon-golden.png";
import contactWhiteIcon from "../../../src/assets/images/contact-icon-white.png";
import contactGoldenIcon1 from "../../../src/assets/images/contact-icon-golden-1.png";
import contactWhiteIcon1 from "../../../src/assets/images/contact-icon-white-1.png";
import contactGoldenIcon2 from "../../../src/assets/images/contact-icon-golden-2.png";
import contactWhiteIcon2 from "../../../src/assets/images/contact-icon-white-2.png";
import contactGoldenIcon3 from "../../../src/assets/images/contact-icon-golden-3.png";
import contactWhiteIcon3 from "../../../src/assets/images/contact-icon-white-3.png";
import AboutGoldenIcon from "../../../src/assets/images/about-icon-golden.png";
import AboutWhiteIcon from "../../../src/assets/images/about-icon-white.png";
import {
  ABOUT_US_ROUTE,
  CONTACT_US_ROUTE,
  HOME_PAGE_ROUTE,
  SERVICE_PAGE_ROUTE,
} from "../../util/Constants";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTheme } from "@emotion/react";
import HomeIcon from "@mui/icons-material/Home";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CallIcon from "@mui/icons-material/Call";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../store/serviceActions";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
});

const AppBarOptions = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const appBarOption = [
  {
    label: "Home",
    path: HOME_PAGE_ROUTE,
    icon: <HomeIcon />,
  },
  // {
  //   label: "Services",
  //   path: HOME_PAGE_ROUTE,
  //   icon: <MiscellaneousServicesIcon />,
  // },

  {
    label: "Services",
    path: HOME_PAGE_ROUTE,
    icon: (
      <Box
        component="img"
        src={contactWhiteIcon}
        alt="icon"
        sx={{
          width: 24, // Set the desired width
          height: 24, // Set the desired height
        }}
      />
    ),
  },
  { label: "Contact us", path: CONTACT_US_ROUTE, icon: <CallIcon /> },
  { label: "About us", path: ABOUT_US_ROUTE, icon: <InfoIcon /> },
];

const MyAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const anchor = "left";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const serviceState = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const { id } = useParams();

  const appBarOption = [
    {
      label: "Home",
      path: HOME_PAGE_ROUTE,
      icon: <HomeIcon />,
    },
    // {
    //   label: "Services",
    //   path: HOME_PAGE_ROUTE,
    //   icon: <MiscellaneousServicesIcon />,
    // },

    {
      label: "Services",
      path: HOME_PAGE_ROUTE,
      icon: (
        <Box
          component="img"
          src={activeIndex == -1 ? contactGoldenIcon2 : contactWhiteIcon2}
          alt="icon"
          sx={{
            width: "23px", // Set the desired width
            height: "23px", // Set the desired height
          }}
        />
      ),
    },
    { label: "Contact us", path: CONTACT_US_ROUTE, icon: <CallIcon /> },
    {
      label: "About us",
      path: ABOUT_US_ROUTE,
      whiteIcon: <InfoIcon />,
      icon: (
        <Box
          component="img"
          src={activeIndex == 3 ? AboutGoldenIcon : AboutWhiteIcon}
          alt="icon"
          sx={{
            width: "20px", // Set the desired width
            height: "20px", // Set the desired height
          }}
        />
      ),
    },
  ];

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    if (serviceState.items.length === 0) {
      dispatch(fetchServices());
    }
  }, [serviceState.items]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname.includes("blog")) {
        setIsSticky(true);
        return;
      }
      const threshold = 100; // Adjust as needed
      const shouldBeSticky = window.scrollY > threshold;
      if (shouldBeSticky !== isSticky) {
        setIsSticky(shouldBeSticky);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  return (
    <AppBar
      className={isSticky ? classes.stickyHeader : classes.fixedHeader}
      sx={{ height: "65px", display: "flex", justifyContent: "center" }}
    >
      <StyledToolbar>
        {/* Menu Icon */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            color: "#fff" ,
            marginLeft: "auto",
            display: { xs: "block", sm: "none" },
          }}
          onClick={() => toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        {/* Application Logo */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            alignSelf: "baseline",
            padding: { xs: 0, md: "10px 0" },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <img width={"100"} src={AppLogo} alt={"logo missing"} />
          </Box>
          {/* <MenuIcon /> */}
        </IconButton>

        {/* For big screen item */}
        <AppBarOptions>
          {appBarOption.map((option, index) => {
            if (option.label === "Services") {
              return (
                <Box>
                  <Button
                    key={index}
                    startIcon={option.icon}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    sx={{
                      ":hover": {
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                      },
                      borderRadius: "0",
                      color:
                        activeIndex == -1 ? theme.palette.primary.main : "#fff",
                      // : isSticky
                      // ? "#000"
                      // : "#fff",
                      textTransform: "none",
                      fontSize: "20px",
                    }}
                    onClick={(event) => {
                      // setActiveIndex(-1);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    {option.label}
                  </Button>
                </Box>
              );
            }
            return (
              <Button
                key={index}
                variant="text"
                // size="large"
                startIcon={option.icon}
                sx={{
                  textTransform: "none",
                  fontSize: "20px",
                  borderRadius: "0",
                  color:
                    activeIndex === index ? theme.palette.primary.main : "#fff",
                  // : isSticky
                  // ? "#000"
                  // : "#fff",
                  ":hover": {
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                  },
                  // "::before": {
                  //   content: '""',
                  //   backgroundColor: "red",
                  //   height: "100%",
                  //   width: "5px",
                  //   transform: "scaleY(0)",
                  //   // position: "absolute",
                  // },
                }}
                // onClick={() => option.onClick(props)}
                onClick={(event) => {
                  setActiveIndex(index);
                  navigate(option.path);
                }}
              >
                {option.label}
              </Button>
            );
          })}
          {/* Language Translator btn */}
        </AppBarOptions>
        <div id="google_element"></div>
        {/* For small Screen items */}
        <Drawer
          anchor={anchor}
          // variant="temporary"
          open={isDrawerOpen}
          onClose={() => toggleDrawer(false)}
          // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <List>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: 2,
                // alignSelf: "baseline",
                // padding: { xs: 0, md: "10px 0" },
                margin: "20px auto",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <img width={"100"} src={AppLogo} alt={"logo missing"} />
              </Box>
              {/* <MenuIcon /> */}
            </IconButton>
            <Divider />

            {appBarOption.map((option, index) => {
              if (option.label === "Services") {
                console.log("diplaying");
                return [
                  <ListItem
                    onClick={() => {
                      // navigate(option.path);
                      // toggleDrawer(false);

                      console.log("waqas");
                      setIsDropdownOpen((oldState) => !oldState);
                    }}
                    key={index}
                    // disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{ color: theme.palette.primary.main }}>
                        {option.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          fontWeight: "bold",
                          color:
                            activeIndex === -1
                              ? theme.palette.primary.main
                              : "#000",
                        }}
                        primary={option.label}
                      />
                      <ListItemIcon>
                        {isDropdownOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>,
                  <ListItem>
                    <Collapse in={isDropdownOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {serviceState.items.map((service, index) => (
                          <ListItemButton
                            onClick={() => {
                              setActiveIndex(-1);
                              toggleDrawer(false);
                              navigate(SERVICE_PAGE_ROUTE + "/" + service.id);
                            }}
                            sx={{ pl: 4 }}
                          >
                            <ListItemIcon>{service.icon}</ListItemIcon>
                            <ListItemText
                              primary={service.name}
                              sx={{
                                color:
                                  activeIndex === -1 && id === service.id
                                    ? theme.palette.primary.main
                                    : "#000",
                              }}
                            />
                          </ListItemButton>
                        ))}

                        {/* <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <StarBorder />
                          </ListItemIcon>
                          <ListItemText primary="Starred" />
                        </ListItemButton> */}
                      </List>
                    </Collapse>
                  </ListItem>,
                ];
              }
              return (
                <ListItem
                  onClick={() => {
                    navigate(option.path);
                    setActiveIndex(index);
                    toggleDrawer(false);
                  }}
                  key={index}
                  // disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ color: theme.palette.primary.main }}>
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontWeight: "bold",
                        color:
                          activeIndex === index
                            ? theme.palette.primary.main
                            : "#000",
                      }}
                      primary={option.label}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          {/* {appBarOption.map((option, index) => {
            return (
              <Button
                key={option.label}
                variant="text"
                sx={{
                  color:
                    activeIndex === index
                      ? theme.palette.primary.main
                      : isSticky
                      ? "#000"
                      : "#fff",
                }}
                // onClick={() => option.onClick(props)}
                onClick={() => {
                  setActiveIndex(index);
                  navigate(option.path);
                }}
              >
                {option.label}
              </Button>
            );
          })} */}
        </Drawer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {serviceState.items.map((service, index) => (
            <MenuItem
              key={index}
              sx={{
                color:
                  activeIndex === -1 && id == service.id
                    ? theme.palette.primary.main
                    : "#000",
              }}
              onClick={() => {
                setAnchorEl(null);
                setActiveIndex(-1);
                navigate(SERVICE_PAGE_ROUTE + "/" + service.id);
              }}
            >
              {service.name}
            </MenuItem>
          ))}
        </Menu>
        <Box sx={{ flexGrow: 1 }} />
      </StyledToolbar>
    </AppBar>
  );
};

export default MyAppBar;
