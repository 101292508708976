import FirebaseService from "../services/FirebaseService";
import { blogActions } from "./blogSlice";
import { uiActions } from "./uiSlice";
import { Timestamp } from "firebase/firestore";

export const fetchBlogs = () => {
  return async (dispatch) => {
    try {
      const blogList = await FirebaseService.fetchBlogs();
      dispatch(
        blogActions.replaceBlogs({
          items: blogList,
        })
      );
    } catch (error) {}
  };
};

export const fetchBlogById = (id) => {
  return async (dispatch) => {
    try {
      const blog = await FirebaseService.findById(FirebaseService.blogsCol, id);
      if (!blog) {
        return null;
      }
      dispatch(
        blogActions.addBlog({
          blog: blog,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};
