import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import AppIcon from "../../assets/logo.png";
import { useTheme } from "@emotion/react";
import classes from "./style.module.css";
//iocns
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router";
import StopIcon from "@mui/icons-material/Stop";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  ABOUT_US_ROUTE,
  CONTACT_US_ROUTE,
  SERVICE_PAGE_ROUTE,
} from "../../util/Constants";
import { useSelector } from "react-redux";

const navigationItemsList = [
  { label: "Home", path: "/" },

  { label: "Services", path: "" },
  { label: "About us", path: ABOUT_US_ROUTE },
  { label: "Contact us", path: CONTACT_US_ROUTE },
];

const followUsList = [
  { name: "meta", icon: FacebookIcon },
  { name: "Instagram", icon: InstagramIcon },
  { name: "linkedIn", icon: LinkedInIcon },
  { name: "Twitter", icon: TwitterIcon },
];

const locationItemsList = [
  {
    icon: <LocationOnIcon />,
    label: "Location: 12000 Ford Rd, Farmers Branch, TX 75234, USA",
  },
  {
    icon: <EmailIcon />,
    label: "Email: contact@thesgconsultants.com",
  },
  {
    icon: <CallIcon />,
    label: "Phone: +1-918-537-4472",
  },
];

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const serviceSlice = useSelector((state) => state.service);
  const StyledFooter = styled(Box)(({ theme }) => ({
    width: "100%",
    // background: theme.palette.primary.main,
    background: "#333333",
    // height: "30vh",
    padding: "2rem 0 1rem 0",
  }));

  const StyledFlexBox = styled(Box)(({ theme }) => ({
    // background: "red",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // justifyContent: {
    //   xs: "flex-start",
    //   sm: "space-around",
    //   lg: "space-around",
    // },
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  }));

  const NavigationItems = styled(Box)(({ theme }) => ({
    // background: "red",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: { xs: "flex-start", sm: "space-between" },
    flexWrap: "wrap",
    listStyle: "none",
    fontWeight: "500",
    textTransform: "uppercase",
    borderTop: `1px solid ${theme.palette.primary.colorDarkGray}`,
    paddingTop: "1rem",
    fontSize: "1.2rem",
    color: theme.palette.primary.textColor,
    height: "80%",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      justifyContent: "flex-start",
      fontSize: "1rem",
      color: "orangered",
    },

    [theme.breakpoints.down("md")]: {
      // display: "none",
      marginBottom: "1rem",
    },
  }));

  const StyledTypography = styled(Box)(({ theme }) => ({
    fontWeight: "400",
    fontSize: "1rem",
    color: theme.palette.primary.textColor,
  }));

  return (
    <StyledFooter>
      <Stack>
        <Box sx={{ textAlign: "center" }}>
          <img src={AppIcon} width="200px" alt="Logo Missing" />
        </Box>
        {/* <br /> */}
        <Typography
          sx={{
            // flexBasis: { lg: "40%", md: "35%", xs: "90%" },
            // flexBasis: { xs: "90%", lg: "70%" },
            width: { xs: "80%", lg: "60%" },
            margin: "auto",
            // textAlign: { xs: "justify", sm: "center" },
            textAlign: "justify",
            // color: { lg: "red" },
            color: "white",
            paddingTop: "2rem",
            fontFamily: "Roboto",
            // fontSize: { xs: "18px", md: "1rem" },
            fontSize: "16px",
            borderTop: `1px solid ${theme.palette.primary.colorDarkGray}`,
            color: theme.palette.otherColor.textColor,
            // padding:"10px"
          }}
        >
          At SHAQ Global Consultants, we strive to provide personalized service
          and expertise to ensure your financial success and compliance with
          regulatory requirements. Partner with us to experience unparalleled
          support and guidance for all your accounting and financial needs.
        </Typography>
        <StyledFlexBox>
          {/* navigation links */}
          <NavigationItems>
            <Typography
              sx={{ marginRight: "30px", textTransform: "none" }}
              variant="h6"
              color={theme.palette.primary.main}
            >
              About SHAQ
            </Typography>
            {navigationItemsList.map((item, index) => (
              <ListItem
                onClick={() => navigate(item.path)}
                index={index}
                item={item}
              >
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <ArrowForwardIosIcon
                    sx={{ color: theme.palette.primary.main }}
                    fontSize="10px"
                  />{" "}
                  {item.label}
                </Stack>
              </ListItem>
            ))}
          </NavigationItems>
          {/* services links */}
          <NavigationItems>
            <Typography
              variant="h6"
              sx={{ textTransform: "none" }}
              color={theme.palette.primary.main}
            >
              Services
            </Typography>
            {serviceSlice.items.map((item, index) => (
              <ListItem
                onClick={() => navigate(SERVICE_PAGE_ROUTE + "/" + item.id)}
                index={index}
                item={item}
              >
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <ArrowForwardIosIcon
                    sx={{ color: theme.palette.primary.main }}
                    fontSize="10px"
                  />{" "}
                  {item.name}
                </Stack>
              </ListItem>
            ))}
          </NavigationItems>
          {/* Address links */}
          <NavigationItems>
            <Typography
              sx={{ marginRight: "30px", textTransform: "none" }}
              variant="h6"
              color={theme.palette.primary.main}
            >
              Address
            </Typography>
            {locationItemsList.map((item, index) => (
              <ListItem index={index} item={item}>
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <ArrowForwardIosIcon
                    sx={{ color: theme.palette.primary.main }}
                    fontSize="10px"
                  />{" "}
                  <span style={{ textTransform: "none" }}>{item.label}</span>
                </Stack>
              </ListItem>
            ))}
          </NavigationItems>

          {/* copyright text */}
        </StyledFlexBox>
        <Box
          sx={{
            // background: "red",
            width: "20%",
            display: "flex",
            margin: "auto",
            marginTop: "2rem",
            justifyContent: { xs: "center", md: "space-around" },
            transition: "all .2s",
          }}
        >
          {/* <IconButton startIcon={<FacebookIcon />} /> */}
          <FacebookIcon key={1} sx={{ fill: "white", fontSize: "3rem" }} />
          <LinkedInIcon key={2} sx={{ fill: "white", fontSize: "3rem" }} />
          <TwitterIcon key={3} sx={{ fill: "white", fontSize: "3rem" }} />
          <InstagramIcon key={4} sx={{ fill: "white", fontSize: "3rem" }} />
        </Box>
        <Typography
          variant=""
          color="#fff"
          sx={{ textAlign: "center", marginTop: "20px" }}
        >
          © 2024 Shaq Global Consultants. All rights reserved.
        </Typography>

        {/* Email and Contact no */}

        {/* <Box
          className={classes.myGrid}
          sx={{ display: "grid", gridTemplateRows: "", gridTemplateColumns: 2 }}
        >
          <StyledTypography>Contact </StyledTypography>
          <StyledTypography>03244840221</StyledTypography>
          <StyledTypography>Email us </StyledTypography>
          <StyledTypography>mrasheed02@gmail.com</StyledTypography>
        </Box> */}
        {/* <StyledTypography>Contact : 03244840221</StyledTypography>
        <StyledTypography>
          Email &nbsp;&nbsp;&nbsp;&nbsp;: mrasheed02@gmail.com
        </StyledTypography> */}

        {/* <Box
          sx={{
            background: "red",
            width: "20%",
            display: "flex",
            margin: "auto",
            marginTop: "1rem",
            justifyContent: "space-around",
          }}
        >
          <FacebookIcon key={1} sx={{ fill: "white", fontSize: "3rem" }} />
          <LinkedInIcon key={2} sx={{ fill: "white", fontSize: "3rem" }} />
          <TwitterIcon key={3} sx={{ fill: "white", fontSize: "3rem" }} />
          <InstagramIcon key={4} sx={{ fill: "white", fontSize: "3rem" }} />
        </Box> */}
      </Stack>
    </StyledFooter>
  );
};

export default Footer;

const ListItem = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      key={props.index}
      onClick={props.onClick}
      // variant="text"
      className={classes.navItem}
      sx={{
        color: "white !important",
        textTransform: "none",
        // fontFamily:"serif",
        fontFamily: "Roboto",

        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.1)",
          boxShadow: `0 1rem 2rem rgba(${theme.palette.secondary.main}, 0.4)`,
          // fontSize: { xs: "12px", md: "1rem" },
        },
        margin: "10px 0",
        fontSize: "16px",
      }}
    >
      {props.children}
    </Box>
  );
};
