import React from "react";
import { Alert, AlertTitle, Link } from "@mui/material";

const SuccessPage = () => {
  return (
    <Alert severity="success" sx={{ mb: 2, marginTop: "100px" ,width:"90%"}}>
      <AlertTitle>Form Submitted Successfully!</AlertTitle>
      Thank you for submitting the form. We will review your information and get
      back to you soon.
      <Link
        to="/schedule-meeting"
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        Schedule a Meeting
      </Link>
    </Alert>
  );
};

export default SuccessPage;
