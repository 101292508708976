import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Navbar from "../components/Navbar/NavBar";

import { InlineWidget } from "react-calendly";
import { useTheme } from "@emotion/react";
import MyAppBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer/footer";
import LocationSection from "../sections/LocationSection/LocationSection";
import TidyCalEmbed from "../components/TidyCal/TidyCal";
import MainHeading from "../components/Typo/MainHeading";
import React, { useState } from "react";
import FirebaseService from "../services/FirebaseService";
import { doSignInWithGoogle } from "../services/AuthService";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/uiSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
// import { DateTimePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AnimatedBtn } from "../components/Header/Header";
import 'animate.css/animate.min.css';
import classes from "./style.module.css";
import ShortHeading from "../components/Typo/shortHeadings";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactUs = () => {
  const [name, setName] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    validateName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    validatePhoneNumber(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    validateMessage(event.target.value);
  };

  const validateName = (value) => {
    if (value.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, name: "Name is required" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^\d{3}[-.]?\d{3}[-.]?\d{4}$/;
    if (!phoneNumberRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid phone number",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
    }
  };

  const validateMessage = (value) => {
    if (value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "Message is required",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    }
  };

  const isFormValid = () => {
    if (name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Invalid Name",
      }));
    }

    if (email.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid Email",
      }));
    }

    if (phoneNumber.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid Phone number",
      }));
    }

    if (message.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "Invalid Name",
      }));
    }

    return (
      name.trim() !== "" &&
      email.trim() !== "" &&
      phoneNumber.trim() !== "" &&
      message.trim() !== "" &&
      Object.values(errors).every((error) => error === "")
    );
  };

  const handleFormSubmission = async () => {
    setOpen(true);
    return;
    if (!isFormValid()) {
      return;
    }

    setIsLoading(true);

    try {
      const user = await doSignInWithGoogle();

      if (user) {
        dispatch(
          uiActions.setNotification({
            message: "Submitting Your Response",
            severity: "info",
          })
        );
        await FirebaseService.writeToCollection(
          FirebaseService.contactInfoCol,
          user.uid,
          {
            name,
            email,
            phoneNumber,
            message,
            user: {
              uid: user.uid,
              // name: user.name,
              email: user.email,
              displayName: user.displayName,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
            },
          }
        );
        //display success notification
        dispatch(
          uiActions.setNotification({
            message: "Submitted Successfully.",
            severity: "success",
          })
        );

        //Display Dialog for scheduling meeting
        setOpen(true);
      } else {
        console.log("unsuccessfull authentication");
        dispatch(
          uiActions.setNotification({
            message: "Authentication Unsuccessfull.",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.setNotification({
          message: error.message,
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const header = (
    <Box class={classes.aboutContainer}>
      {/* Heading */}
      <Box className={classes.test}>
        <Typography
          sx={{
            margin: "0 !important",
            lineHeight: 0.7,
            color: theme.palette.primary.main,
          }}
          className="headingPrimary greenText animate__animated  animate__fadeInDown"
        >
          We are
        </Typography>
        <Typography
          sx={{
            margin: "0 !important",
            lineHeight: 1,
            color: theme.palette.primary.main,
          }}
          className="headingPrimary whiteText animate__animated  animate__fadeInDown"
        >
          here to help
        </Typography>
        <ShortHeading sx={{textAlign:"left",color:"#fff"}}>
        We will happily offer you a free consultation
        <br /> to determine how we can best serve you.
        </ShortHeading>
        <AnimatedBtn
        // href="href="#services" style={{ "scroll-behavior": "smooth" }}"
          variant="contained"
          size="medium"
          // className={ "animate__animated animate__bounce "}
          className={`animate__animated ${classes.bgColorAnimation}`}
          // className={ "animate__animated " + classes.bgColorAnimation}
          href="#contactForm"
          sx={{
            width: "max-content",
            padding: "10px 20px",
            "scroll-behavior": "smooth",
            // animation: "animate__bounce 1s infinite 2s",
          }}
          // onClick={() => navigate(CONTACT_US_ROUTE)}
        >
          Connect with us
        </AnimatedBtn>
      </Box>
    </Box>
  );

  const dialog = (
    <Dialog
      open={open}
      fullScreen={showMeeting}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {showMeeting && (
        <Box>
          <AppBar
            sx={{
              position: "relative",
            }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, color: "white" }}
                variant="h6"
                component="div"
              >
                Shaq Global Consultants
              </Typography>
            </Toolbar>
          </AppBar>
          <TidyCalEmbed />
        </Box>
      )}
      {!showMeeting && (
        <Box>
          <DialogTitle
            sx={{
              display: "flex ",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <CheckCircleIcon
              sx={{
                fontSize: 48,
                color: "green",
              }}
            />{" "}
            {/* Success Icon */}
            {"Form submitted successfully."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Do you want to schedule a meeting as well?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={() => setShowMeeting(true)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );

  // const fullScreenDialog=;

  return (
    <Stack>
      {/* <MyAppBar /> */}
      {header}
      {/* <Box sx={{ margin: "10px 0" }}> */}
      <Container id="contactForm" maxWidth="md" sx={{ py: 4 }}>
        <Paper elevation={2} sx={{ p: 4 }}>
          {/* <Typography variant="h2" gutterBottom>
            Get in Touch
          </Typography> */}
          <MainHeading sx={{ textAlign: "left", color: "black" }}>
            Contact us
          </MainHeading>
          <Typography variant="body1" gutterBottom>
            If you have any question / query, please fill out the form, and our
            team will get back to you shortly
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={name}
                  onChange={handleNameChange}
                  error={errors.name !== ""}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={email}
                  onChange={handleEmailChange}
                  error={errors.email !== ""}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  label="Contact No"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={errors.phoneNumber !== ""}
                  helperText={errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="message"
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                  value={message}
                  onChange={handleMessageChange}
                  error={errors.message !== ""}
                  helperText={errors.message}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <DateTimePicker
                  sx={{ width: "100%" }}
                  label="Schedule half hour meeting"
                  onChange={handleDateTimeChange}
                />
              </Grid> */}
            </Grid>

            <Box mt={2} sx={{ textAlign: "right" }}>
              <Button
                disabled={isLoading}
                onClick={handleFormSubmission}
                variant="contained"
                color="primary"
                size="large"
                sx={{ color: "#fff", textTransform: "none" }}
              >
                {!isLoading ? "Submit" : <CircularProgress />}
              </Button>
            </Box>
          </form>
          {/* <Typography variant="body1" gutterBottom sx={{ mt: 4 }}>
            Or, you can reach us at:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Phone: +1-918-537-4472" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Email: contact@thesgconsultants.com" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Address: 123 Main St, Anytown, USA" />
            </ListItem>
          </List> */}
        </Paper>
      </Container>

      {/*  */}
      <LocationSection />
      {/* </Box> */}
      {/* <Box>
        <TidyCalEmbed />
      </Box> */}
      {dialog}
      {/* <Footer /> */}
    </Stack>
  );
};

export default ContactUs;
