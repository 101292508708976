import React from "react";
import Typography from "@mui/material/Typography";

const Paragraph = ({ children, sx, ...rest }) => {
  return (
    <Typography
      variant="p"
      sx={{
        // textAlign: "body1",
        lineHeight: 1.5,
        // flexBasis: { md: '100%', lg: '40%' },

        fontFamily: "Serif",
        fontSize: {
          xs: "14px",
          sm: "16px",
          md: "18px",
          lg: "20px",
          xl: "24px !important",
        },
        textAlign:"justify",
        display:"block",
        ...sx, // Merge custom sx props
      }}
      {...rest} // Pass any other props to Typography
    >
      {children}
    </Typography>
  );
};

export default Paragraph;
