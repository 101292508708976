import { Box, Paper, Typography } from "@mui/material";
import classes from "./style.module.scss";
import { useTheme } from "@emotion/react";
import CountUp from "react-countup";
import { useEffect, useRef, useState } from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const contentList = [
  {
    heading: "Simplify Your Taxes",
    icon: <ScheduleIcon sx={{fontSize:"50px"}} />,
    count: 50,
    subHeading: "Hours we save our clients each year",
  },
  {
    heading: "",
    icon: <CurrencyExchangeIcon sx={{fontSize:"50px"}} />,
    count: 2500,
    subHeading: "Cost we save our clients each year",
  },
  // {
  //   heading: "Vyde does the paperwork so you can focus on your business.",
  //   icon: ribbonImg,
  //   count: 0,
  //   subHeading: "Clients audited in by the IRS in the past 7 years",
  // },
];

const StatsSection = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.25 } // Trigger animation when at least 25% of the element is visible
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={serviceRef}
      sx={{
        background: "#F1F1F1",
        width: "100%",
        margin: "50px 0",
        padding: "50px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          padding: "10px",
          flexDirection: "column",
          maxWidth: "1120px",
          //   background: "cyan",

          margin: "auto",
          gap: "50px",
        }}
      >
        {/* heading and description bar */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/*  */}
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "20px !important",
                sm: "30px !important",
                md: "40px !important",
                lg: "50px !important",
              },
            }}
            className={classes.heading}
          >
            Have peace of mind <br /> while we handle your financial matters.
          </Typography>
          {/* <Typography className={classes.subHeading} sx={{ maxWidth: "342px" }}>
            Vyde does the paperwork so you can focus on your business.
          </Typography> */}
        </Box>
        {/* counts */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "60px",
            flexWrap: "wrap",
          }}
        >
          {contentList.map((item, index) => {
            return (
              <Box key={index} sx={{ flex: 1, minWidth: "200px" }}>
                {/* Separator */}
                <div
                  className={classes.separator}
                  style={{ background: theme.palette.primary.textColor }}
                />
                {/* icon and count */}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  {/* <img src={item.icon} /> */}
                  {item.icon}
                  <Typography className={classes.number}>
                    {index==1 && "$"}
                    {isVisible && <CountUp end={item.count} duration={5} />}+
                  </Typography>
                </Box>
                {/* subheading */}
                <Typography variant="h5">{item.subHeading}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default StatsSection;
